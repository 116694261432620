<template>
  <div class="home">
    <div class="home-top">
      <BannerCheat v-if="isBotChromeLighthouse" />
      <BannerSection v-else />
    </div>
    <div class="home__content container-custom">
      <GameCenter />
      <Casino />
      <TopNohu />
      <Promotion />
    </div>
  </div>
</template>

<script setup lang="ts">
import { LazyHydrationWrapper } from 'vue3-lazy-hydration'
import { useAppStore } from '~/store/app'
const BannerSection = defineAsyncComponent(() => import('@/components/desktop/pages/home/hero-banner/index.vue'))
const BannerCheat = defineAsyncComponent(() => import('@/components/desktop/pages/home/hero-banner/banner-cheat.vue'))
const GameCenter = defineAsyncComponent(() => import('@/components/desktop/pages/home/game-center/index.vue'))
const Casino = defineAsyncComponent(() => import('@/components/desktop/pages/home/casino/index.vue'))
const TopNohu = defineAsyncComponent(() => import('@/components/desktop/pages/home/top-nohu/index.vue'))
const Promotion = defineAsyncComponent(() => import('@/components/desktop/pages/home/promotion/index.vue'))

const { isBotChromeLighthouse } = useAppStore()

onMounted(() => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
})
</script>

<style lang="scss" scoped src="~/public/assets/scss/components/desktop/pages/home/index.scss"></style>
